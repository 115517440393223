import main from "./main"

export class teras extends main{

   constructor (args) {
      super(args)
      this.name = "www.eflm.eu"
      this.version = "1.0.1"
      this.urlsite = (process.env.NODE_ENV != "development") ? document.location.origin + "/site/" : ""

      this.siteurl= "https://www.eflm.eu"
      this.defaultPageTitle= "EFLM"

      this.apiUrl = "https://api.terasmm.it/201806/"
      this.mysql = {
         composer: "db",
         adapter: "contabo01-2023",
         database: "eflm_website"
      }
      this.minWidthMobile= 760
      
      this.homeSliderRatio= {
         "desktop": 1/4,
         "tablet": 1/3,
         "mobile": 3/4
      }

      this.lengthWord= {
         1: "one",
         2: "two",
         3: "three",
         4: "four",
         5: "five",
         6: "six",
         7: "seven",
         8: "eight",
         9: "nine",
         10: "ten",
         11: "eleven",
         12: "twelve",
         13: "thirteen",
         14: "fourteen",
         15: "fifteen",
         16: "sixteen",
         17: "seventeen",
         18: "eighteen",
         19: "nineteen",
         20: "twenty"
      }
      
      
      this.infografiche= []
      //this.awards_home= []
      this.awardsAree= []
      this.awardsPerTipologia= []
      this.board= []
      this.boardPerCarica= []
      this.eventi= []
      this.evento_home= {}
      this.generalmeetingsAree= []
      this.opportunita= []
      this.opportunita_home= []
      this.nationalguidelines_topic= []
      this.nationalguidelinesPerTopic= {}
      this.news= []
      this.news_home= []
      this.newsletter_home= {}
      this.newslettersPerAnno= []
      this.presidenti= []
      this.publicazione_home= {}
      this.publicazioni= []

      this["404pageTitolo"]= "Not Found"

      super.init()
      return this
   }

 
}
export default new teras
