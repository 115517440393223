import { createApp } from "vue/dist/vue.esm-bundler"; 
import { h, reactive} from "vue"
import App from "./App.vue"


/* Inizio Router */
import { createVueRouter } from "./router"
/* Fine Router */

/* Inizio ElementPlus */
import { ElLoading, ElMessage, ElMessageBox, dayjs } from "element-plus";
//Importa il primo giorno della settimana nei picker al Lunedì
dayjs().$locale().weekStart = 1;
//import "./css/element-var.css"
/* Fine ElementPlus */

/* Inizio Luxon */
//https://moment.github.io/luxon/#/moment
//https://moment.github.io/luxon/#/formatting?id=table-of-tokens
import { DateTime as luxon, Settings as luxonSettings } from "luxon"
luxonSettings.defaultLocale= "en"
luxonSettings.defaultZone= "Europe/Rome"
/* Fine Luxon */

/* Inizio TerAs */
import terasImp from "./teras"
var teras= reactive(terasImp)
import axios from "./teras/tAxios"
import aes from "./teras/aes"
import storage from "./teras/tStorage" 

import "./css/big.css"
/* Fine TerAs */

/* Inizio Global Function */
teras.event.on("checkFirst", async function(from, to) {
   await axios.query("fetchAll", 
      `SELECT slide.id, slide.nome, slide.immagine_desktop, slide.immagine_tablet, slide.immagine_mobile, slide.link, slide.link_blank, bg.colore
      FROM home_infografica AS slide LEFT JOIN _homeinfografica_background AS bg ON bg.chiave= slide.background
      WHERE slide.id>0 AND NOW()>=visibilita_inizio AND (visibilita_fine='0000-00-00 00:00:00' OR (visibilita_fine<>'0000-00-00 00:00:00' AND NOW()<=visibilita_fine)) 
      ORDER BY peso`, {}, teras, "infografiche")

   //await axios.query("fetchAll", `SELECT * FROM progetto WHERE id>0`, {}, teras, "progetti")
   teras.awardsAree= []
   await axios.query("fetchAll", 
      `SELECT awtipo.id, awtipo.label, awtipo.descrizione, awtipo.sponsor, awtipo.sponsorurl, awarea.id AS area, awarea.label AS area_label
      FROM awards_tipologia AS awtipo LEFT JOIN awards_tipologia_area AS awarea ON awtipo.area= awarea.id
      WHERE awtipo.id>0 AND awtipo.attivo= 1
      ORDER BY awtipo.id`, {}, teras, "awardsPerTipologia")
   for(var tipologia of teras.awardsPerTipologia){
      if(tipologia.awards==undefined){
         tipologia.awards= []
      }
      if(teras.items(teras.awardsAree, tipologia.area, "id", "id")==undefined){
         teras.awardsAree.push({ id: tipologia.area, label: tipologia.area_label, tipologie: [] })
      }
      teras.items(teras.awardsAree, tipologia.area, "id").tipologie.push(tipologia)
   }
   var awards= await axios.query("fetchAll", 
      `SELECT aw.id, aw.anno, CONCAT(aw.nome, " ", aw.cognome) AS nominativo, aw.nazione, aw.altro, aw.img, aw.inevidenza, aw.tipologia, naz.nomeeng AS nazione_label
      FROM awards AS aw LEFT JOIN codicefiscale.nazione AS naz ON naz.iso= aw.nazione
      WHERE aw.id>0
      ORDER BY tipologia, anno DESC`, {})
   for(var award of awards.result){
      for(var tipologia of teras.awardsPerTipologia){
         if(award.tipologia!=tipologia.id){
            continue;
         }
         tipologia.awards.push(award)
      }
      /*if(!tipologieAwardsInserite.includes(award.tipologia)){
         teras.awardsPerTipologia.push({ id: award.tipologia, label: award.tipologia_label, descrizione: award.tipologia_descrizione, sponsor: award.sponsor, sponsorurl: award.sponsorurl, awards: [] })
      }
      for(var tipologia of teras.awardsPerTipologia){
         if(tipologia.id==award.tipologia){
            tipologia.awards.push(award)
         }
      }*/
   }

   await axios.query("fetchAll", 
      `SELECT mb.id, CONCAT(mb.nome, " ", UPPER(mb.cognome)) AS nominativo, mb.carica AS carica_id, mb_c.nome AS carica_nome, mb.carica_altro, mb.affiliazione, mb.img 
       FROM board AS mb LEFT JOIN board_carica AS mb_c ON mb.carica= mb_c.id 
       WHERE mb.id>0 AND mb_c.attivo= 1 AND mb.attivo= 1 
       ORDER BY mb_c.peso, mb.peso`, {}, teras, "board")
   teras.boardPerCarica= []
   var caricheInserite= []
   for(var membro of teras.board){
      if(!caricheInserite.includes(membro.carica_id)){
         teras.boardPerCarica.push({ id: membro.carica_id, label: membro.carica_nome, membri: [] })
         caricheInserite.push(membro.carica_id)
      }
      for(var carica of teras.boardPerCarica){
         if(carica.id==membro.carica_id){
            carica.membri.push(membro)
         }
      }
   }

   await axios.query("fetchAll", 
      /*`SELECT id, titolo, descrizione, CONCAT("<div>", descrizionebreve, "</div><div>", luogo, "</div>") AS descrizionebreve, luogo, svoltoil, tipo, link, inevidenza
      FROM evento
      WHERE id>0 AND attivo= 1
      ORDER BY svoltoil DESC`*/
      `SELECT id, titolo, descrizione, luogo AS descrizionebreve, luogo, svoltoil, tipo, link, inevidenza
      FROM evento
      WHERE id>0 AND attivo= 1
      ORDER BY svoltoil DESC`, {}, teras, "eventi")
   for(var evento of teras.eventi){
      if(evento.inevidenza==1 && teras.evento_home.id==undefined){
         teras.evento_home= evento
      }
   }

   await axios.query("fetchAll", 
      `SELECT *, CONCAT(anno, ": ", nome) AS label FROM generalmeeting_area WHERE id>0 AND attivo=1 ORDER BY peso DESC`, {}, teras, "generalmeetingsAree")
   var meetings= await axios.query("fetchAll", `SELECT * FROM generalmeeting WHERE id>0 AND attivo=1 ORDER BY peso DESC`, {})
   for(var area of teras.generalmeetingsAree){
      if(area.meetings==undefined){
         area.meetings= []
      }
      for(var meeting of meetings.result){
         if(area.id==meeting.area){
            area.meetings.push(meeting)
         }
      }
   }

   await axios.query("fetchAll", 
      `SELECT op.id, op.titolo, op.link, optipo.img, op.descrizionebreve, op.inevidenza, op.inevidenza_peso, op.tipologia, optipo.label AS tipologia_label
      FROM opportunita AS op LEFT JOIN _opportunita_tipologia AS optipo ON op.tipologia= optipo.id 
      WHERE op.id>0 AND optipo.id>0
      ORDER BY op.attivo_peso`, {}, teras, "opportunita")
   for(var op of teras.opportunita){
      if(op.inevidenza==1){
         teras.opportunita_home.push(op)
      }
   }
   teras.sortAsc(teras.opportunita_home, "inevidenza_peso", "number")

   await axios.query("fetchAll", `SELECT id, url, nome FROM nationalguidelines_topic WHERE id>0 ORDER BY nome`, {}, teras, "nationalguidelines_topic")
   var guidelines= await axios.query("fetchAll", 
      `SELECT ng.id, ng.anno, naz.nomeeng AS nazione, naz.isoflag AS nazione_flag, ng.nome, ng.link_linguaoriginale, ng.link, ng.topic
      FROM nationalguidelines AS ng LEFT JOIN codicefiscale.nazione AS naz ON naz.iso3= ng.nazione
      WHERE ng.id>0 AND ng.attivo= 1 
      ORDER BY ng.anno, ng.nome`, {})
   teras.nationalguidelinesPerTopic= {}
   for(var topic of teras.nationalguidelines_topic){
      if(!Array.isArray(teras.nationalguidelinesPerTopic[topic.id])){
         teras.nationalguidelinesPerTopic[topic.id]= []
      }
      for(var guideline of guidelines.result){
         if(guideline.topic==topic.id){
            teras.nationalguidelinesPerTopic[topic.id].push(guideline)
         }
      }
   }
   console.log(teras.nationalguidelinesPerTopic)
   

   await axios.query("fetchAll", 
      `SELECT id, titolo, link, descrizione, REPLACE(REPLACE(descrizionebreve, "<p>", "<div>"), "</p>", "</div>") AS  descrizionebreve, pubblicatoil, inevidenza, inevidenza_peso
      FROM news 
      WHERE id>0 AND attivo=1 
      ORDER BY pubblicatoil DESC`, {}, teras, "news")
   for(var news of teras.news){
      if(news.inevidenza==1 && teras.news_home.length<2){
         teras.news_home.push(news)
      }
   }

   var newsletters= await axios.query("fetchAll", `SELECT *, CONCAT(anno, " | ", nome) AS label FROM newsletter WHERE id>0 AND attivo=1 ORDER BY pubblicatoil DESC`, {})
   var anniInseriti= []
   for(var news of newsletters.result){
      if(!anniInseriti.includes(news.anno)){
         teras.newslettersPerAnno.push({ label: "EFLM Newsletters "+news.anno, anno: news.anno, news: [] })
         anniInseriti.push(news.anno)
      }
      if(news.inevidenza==1 && teras.newsletter_home.id==undefined){
         teras.newsletter_home= news
      }
      for(var annoNews of teras.newslettersPerAnno){
         if(annoNews.anno==news.anno){
            annoNews.news.push(news)
         }
      }
   }

   await axios.query("fetchAll", 
      `SELECT pres.*, CONCAT(pres.nome, " ", UPPER(pres.cognome)) AS nominativo, naz.nomeeng AS nazione_label 
      FROM presidente AS pres LEFT JOIN codicefiscale.nazione AS naz ON naz.iso= pres.nazione 
      WHERE pres.id>0 
      ORDER BY pres.peso DESC`, {}, teras, "presidenti")

   await axios.query("fetchAll", 
      `SELECT id, tipo, titolo, descrizione, descrizionebreve, url, allegato, inevidenza, "" AS link, autore, keywords
      FROM publication
      WHERE id>0 AND attivo= 1
      ORDER BY datapublicazione DESC`, {}, teras, "publicazioni")

   for(var publicazione of teras.publicazioni){
      if(publicazione.inevidenza==1){
         publicazione.link= teras.calcolaLinkPublicazione(publicazione)
         teras.publicazione_home= teras.copiaOggetto(publicazione)
      }
   }
   
   teras.check.first = true
   //if(router.options.history.location!=to.path)
      router.replace(to.path)
})
/* Fine Global Function */

/* Inizio TerAs-event */
teras.event.on("checkAuth", async (from,to) =>{
   console.log("CHECK AUTH")
   if(storage.exist("utente")){
      console.log("YES, PRESENTE UTENTE IN LOCAL STORAGE....LO RICARICO DA LI..."+to.path)
      teras.utente = storage.load("utente")
      teras.event.emit("login",to.path)
   }else{
      teras.check.auth = false
      teras.event.emit("errore",-1,"È necessario effettuare l\'accesso prima di poter accedere alla pagina")
      if(from.path!="/login")
         router.replace("/login")
   }
})
teras.event.on("appReady", function() {
   console.log("appReady appReady appReady appReady appReady appReady appReady")
   router.push(teras.app.routeInitial)
})
teras.event.on("appExit", function() {
})

teras.event.on("setSeoInformation", async function(page){
   new Promise((resolve) => {
      teras.event.emit("removeSeoInformationFromPrevPage", resolve)
   }).then(async (value) => {
      //Imposto il meta title e description per la pagina statica recuperata
      for(var i=0; i<page.head.children.length; i++){
         if(page.head.children[i].attributes[0].nodeName=="title"){
            console.log("SEO title: " +page.head.children[i].attributes[0].nodeValue)
            var meta = document.createElement("meta");
            meta.title= page.head.children[i].attributes[0].nodeValue
            document.getElementsByTagName("head")[0].appendChild(meta);
         }
         if(page.head.children[i].attributes[0].nodeName=="name" && page.head.children[i].attributes[0].nodeValue=="description" && 
            page.head.children[i].attributes[1]!=undefined && page.head.children[i].attributes[1].nodeName=="content"){
            console.log("SEO description: " +page.head.children[i].attributes[1].nodeValue)
            var meta = document.createElement("meta");
            meta.name="description"
            meta.content = page.head.children[i].attributes[1].nodeValue
            document.getElementsByTagName("head")[0].appendChild(meta);
         }
      }
   })
})
teras.event.on("removeSeoInformationFromPrevPage", function(callback){
   //Salvo dentro l"array nodeToRemove i tag meta della pagina statica precedente che sono da rimuovere per inserire quelli nuovi
   var nodeToRemove= []
   for(var i=0; i<document.getElementsByTagName("head")[0].children.length; i++){
      var node= document.getElementsByTagName("head")[0].children[i]
      if(node.localName=="meta" && node.attributes[0].nodeName=="title"){
         nodeToRemove.push(node)
      }
      if(node.localName=="meta" && node.name=="description"){
         nodeToRemove.push(node)
      }
   }
   for(var i=0; i<nodeToRemove.length; i++){
      document.getElementsByTagName("head")[0].removeChild(nodeToRemove[i])
   }
   if(callback!=undefined){
      //console.log("callback")
      callback(true)
   }
})

window.addEventListener("resize",() => { 
   teras.getDevice()
   teras.event.emit("appResize")
})
window.addEventListener("scroll",() => { 
   teras.getDevice()
   teras.event.emit("appScroll")
})

/* Fine TerAs-event */

/* Inizio Vue-3 */

const app= createApp({
   render: () => h(App)
})

app.config.globalProperties.$teras= teras
app.config.globalProperties.$aes = aes
app.config.globalProperties.$axios = axios
app.config.globalProperties.$luxon = luxon
app.config.globalProperties.$storage = storage

const router = createVueRouter(app)
app.use(router)
app.use(ElLoading)
app.use(ElMessage)
app.use(ElMessageBox)

app.mount("#app")

teras.vueAppInstance= app

/* Fine Vue-3 */
